.centerBox {
	display: flex;
	justify-content: center;
}

.login-card-header {
	margin: 10px;
	padding: 10px;
}

.login-card-header img {
	width: 35px;
	height: auto;
}

.login-box {
	padding: 30px;
	z-index: 100;
	text-align: center;

	.login-icon {
		font-size: 60px;
		display: flex;
		align-self: center;
		margin-bottom: 20px;
	}

	h2 {
		font-size: 20px;
	}

	button {
		display: flex;
		align-self: center;
		max-width: max-content;
	}
}

.teamsDRloginButton {
	display: flex;
	justify-content: center;
}

// Custom parent styling to center login
.page-content:has(div.login-box) {
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;
	align-items: center;
}
