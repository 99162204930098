.loading-icon-container {
  margin-bottom: 10px;

  .loading-icon svg {
    font-size: 2.5rem;
  }

  .shimmer {
    display: inline-block;
    mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
    background-repeat: no-repeat;
    animation: shimmer 2.5s infinite;
    font-size: 50px;
    max-width: 200px;
  }

  @keyframes shimmer {
    100% {
      -webkit-mask-position: left;
    }
  }
}
