.settings-container {
	// All the tab panel content styling
	.settings-option {
		display: flex;
		flex-direction: column;
		row-gap: 10px;

		.settings-header {
			display: flex;
			justify-content: space-between;
			column-gap: 30px;
			align-items: center;

			.settings-header-text {
				display: flex;
				flex-direction: column;
				row-gap: 10px;

				h2 {
					font-size: 20px;
				}

				p {
					color: #808080;
				}
			}
		}

		.settings-form {
			display: grid;
			grid-template-columns: 1fr 2fr;
			column-gap: 150px;
			margin: 30px 0;

			.settings-label {
				display: flex;
				flex-direction: column;
				row-gap: 20px;
			}

			.settings-input {
				display: flex;
				flex-direction: column;
				row-gap: 30px;

				&.image {
					row-gap: 10px !important;
				}

				.label-input {
					display: flex;
					flex-direction: row;
					column-gap: 30px;
				}

				.dual-input {
					display: flex;
					flex-direction: row;
					column-gap: 100px;
				}
			}
		}
	}
}

.four {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	text-align: center;
	padding: 10px;
}
