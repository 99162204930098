.description {
	margin: 0 0 1.5rem 0;
	color: #808080;
	font-size: 20px;
}
.admin-title-container {
	margin-top: 1.5rem;
	display: flex;
	justify-content: space-between;
}
.tab:focus {
	color: #000;
	font-weight: 600;
}
.tab {
	font-weight: 400;
	color: #000;
}
.add-members-btn {
	font-size: 16px;
	line-height: 19.36px;
	font-weight: 400;
	width: 192px;
	height: 36px;
	border-color: #5341b2;
	border-radius: 5px;
}
.admin-title {
	margin-bottom: 1rem;
}
