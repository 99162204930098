.tum-overview-container {
	position: relative;
	padding: 20px;
	display: grid;
	grid-template-columns: 1fr 0.02fr 1fr 0.02fr 1fr;
	column-gap: 15px;
	border-radius: 20px;

	.overview {
		display: flex;
		flex-direction: column;
		row-gap: 15px;

		.ovw-header {
			display: flex;
			align-items: center;
			justify-content: center;
			column-gap: 15px;

			h4 {
				justify-content: center;
			}
		}

		.overview-values {
			display: grid;
			grid-template-columns: 1fr 0.02fr 1fr;
			column-gap: 15px;

			.ovw {
				display: flex;
				flex-direction: column;
				align-items: center;
				text-align: center;
			}
		}

		.overview-text {
			text-align: center;
		}
	}
}
