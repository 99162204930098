@import '../../../../../styles/root-style.scss';

.centerBox {
	@include flex-box-ctr;
}

.card-header {
	display: grid;
	grid-template-columns: 45px auto 45px;
}
