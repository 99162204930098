// Import from root-style
@import '../../../../styles/root-style.scss';

.sidemenu-container {
	// Flexbox
	display: flex;
	flex-direction: column;
	row-gap: 15px;
	// Fixed style
	position: fixed;
	top: 0;
	left: 0;
	border-radius: 0 var(--border-radius) var(--border-radius) 0;
	// Other

	padding: 30px 0 20px 0;
	min-height: calc(100vh - 50px);
	border-right: 1px solid var(--border) !important;
	overflow-x: hidden; // Changed to "hidden" to hide horizontal overflow
	overflow-y: auto; // Added to enable vertical scrolling

	// This will be changed
	width: 300px;

	// Smooth transition
	transition: width 0.5s ease;

	&.close {
		width: 84px !important;

		footer p,
		img,
		.avatar-box-text {
			display: none;
		}
	}

	.sidemenu-content {
		// Flexbox
		@include flex-box-col;
		padding: 0 20px;
		flex: 1;

		// Header
		.sidemenu-header {
			display: flex;
			align-items: center;
			flex-direction: row;
			column-gap: 40px;

			.menu-icon {
				padding: 10px;
				border-radius: 15px;
			}
		}

		// Navigation
		nav {
			display: flex;
			flex-direction: column;
			row-gap: 10px;
			flex: 1;
			position: relative;
			overflow: hidden;
		}

		.menu-list {
			display: flex;
			flex-direction: column;
			row-gap: 5px;

			.menu-item {
				border-radius: 15px;
				padding: 10px;
				align-items: center;
				flex-direction: row;
				column-gap: 5px;
			}
		}

		// Logo
		img {
			max-width: 150px;
		}

		.extra {
			@include flex-box-col;
			justify-content: flex-end;
		}

		// Extras
		.sidemenu-extras {
			@include flex-box-col;
			justify-content: flex-end;
		}
	}

	.sidemenu-avatar {
		background-color: var(--bg-primary);
		padding: 20px 22px;
	}

	footer {
		padding: 0 20px;
		text-align: center;

		p {
			font-size: 14px !important;
		}
	}
}

// Empty display - When user is not logged in
.sidemenu-placeholder {
	background-image: url('../../../../assets//images//generic/background/hexagon-bg.png');
	background-position: center;
	background-size: auto;
	border-radius: 0 15px;
}
