.update-handler-container {
	max-height: 400px;
	overflow-y: scroll;

	.empty {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.update-tabs {
		.update-tab {
			display: flex;
			flex-direction: row;
			column-gap: 10px;
			align-items: center;

			.total {
				padding: 1px 5px;
				border-radius: 5px;

				span {
					font-size: 12px !important;
				}
			}
		}
	}

	.tab-panel {
		padding: 10px 0 !important;
	}

	hr {
		margin: 20px 0 20px 0;
	}

	// Update row display
	.tum-update-row-container {
		display: flex;
		flex-direction: row;
		column-gap: 20px;

		.icon-box {
			padding: 20px;
			border-radius: 10px;
		}

		.information {
			display: flex;
			flex-direction: column;
			row-gap: 10px;
			flex: 1;
			padding: 0 10px 0 0;

			.actions {
				display: flex;
				justify-content: space-between;
				align-items: center;

				p.time-diff {
					font-size: 12px !important;
					color: #808080;
				}

				.tum-status-buttons {
					display: flex;
					flex-direction: row;
					column-gap: 5px;

					button {
						font-size: 12px !important;
					}
				}
			}

			p.status {
				display: flex;
				align-items: center;
				column-gap: 5px;
			}
		}
	}
}
