.add-customer-description-button {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin: 0 0 1.5rem 0;
}

.add-customer-description {
	color: #808080;
	font-size: 16px;
}

.add-customer-heading {
	font-size: 20px !important;
}
