.customer-form-container {
	padding: 0px 30px;

	.customer-form-divider {
		margin: 30px 0px;
	}
}
.customer-form-header {
	display: flex;
	margin: 30px 0;
	align-items: center;
}

.customer-form-header-text {
	margin: 0 0 0 20px;
}

.customer-form-customer-details {
	margin: 30px 0;
	display: grid;
	grid-template-columns: 1fr 400px;
	row-gap: 30px;
	align-items: center;
}

.customer-form-antswitch {
	display: flex;
	align-items: center;
	gap: 10px;
}

.customer-form-checkbox-container {
	display: flex;
	justify-content: space-between;
	grid-column: 1 / 3;
}

.phone-number {
	display: flex;
	flex-direction: row;
	column-gap: 5px;
}

.add-customer-button-container {
	display: flex;
	justify-content: center;
}

span.required {
	color: #db3d47 !important;
}

.customer-form-section {
	margin-bottom: '15px';
	padding: '15px';
	color: pink;
	border-width: '0.2px' !important;
}

.customer-form-heading-divider {
	grid-column: span 2;
}
