.tum-loading-row-container {
	display: flex;
	justify-content: space-between;
	padding: 0 20px;

	.row-text {
		display: flex;
		flex-direction: column;
		row-gap: 10px;
	}

	.row-icon {
		display: flex;
		align-items: center;
	}
}
