@import '../../styles/root-style.scss';

.centerBox {
	@include flex-box-ctr;
}

.home-container {
	.dashboard-container {
		display: flex;
		flex-direction: column;
		row-gap: 30px;

		.dashboard-card-container {
			display: grid;
			grid-template-columns: 1fr 1fr;
			column-gap: 30px;
			row-gap: 30px;

			.teamsImageBox {
				@include flex-box-ctr;
			}

			.teamsImageBox img {
				width: 50px;
				height: auto;
			}

			.teamsImageBox-sml {
				margin-top: 5px;
				display: flex;
				justify-content: center;
			}

			.teamsImageBox-sml img {
				width: 30px;
				height: auto;
			}

			.teamsConfigImageBox {
				@include flex-box-ctr;
			}
			.teamsConfigImageBox img {
				width: 250px;
				height: auto;
			}
		}

		.dashboard-card-overview {
			display: grid;
			grid-template-columns: 3fr 1fr;
			column-gap: 30px;
		}
	}
}

canvas {
	max-width: 200px;
}
