.tum-users-container {
	display: flex;
	flex-direction: column;
	row-gap: 20px;

	.MuiDataGrid-root .MuiDataGrid-cell {
		white-space: nowrap !important;
	}

	.tum-pagination {
		display: flex;
		justify-content: flex-end;
		column-gap: 20px;
	}
}

.tum-user-edit-container {
	display: flex;
	flex-direction: column;
	row-gap: 20px;
	height: 100vh;
	padding: 30px 40px;

	.tum-user-content {
		display: flex;
		flex-direction: row;
		column-gap: 15px;
		border-radius: 15px;
	}

	.tum-edit-content {
		display: flex;
		flex-direction: column;
		row-gap: 20px;
		flex: 1;

		.tum-input {
			display: flex;
			flex-direction: column;
			row-gap: 15px;
		}
	}

	.tum-edit-buttons {
		display: grid;
		grid-template-columns: 1fr 0.01fr 1fr;
		column-gap: 20px;
	}
}
