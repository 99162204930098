.li-container {
	display: flex;
	flex-direction: column;
	row-gap: 10px;

	.li-content {
		text-align: center;
		display: flex;
		flex-direction: column;
		flex: 1 1 100%;
		flex-wrap: wrap;
		align-items: center;
		align-content: center;
		justify-content: center;

		.li-content-flex {
			display: flex;
			justify-content: space-between;
			column-gap: 20px;
		}

		.li-content-col {
			display: flex;
			flex-direction: column;
			row-gap: 10px;
			text-align: center;
			align-content: center;
			flex-wrap: wrap;

			svg {
				font-size: 30px;
				margin-bottom: 10px;
			}

			h2 {
				font-size: 30px;
			}
		}

		.li-content-desc {
			display: flex;
			flex-direction: column;
			row-gap: 20px;
		}
	}
}
