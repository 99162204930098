.order-overview-container {
	display: flex;
	flex-direction: column;
	row-gap: 20px;

	.order-overview-filters {
		display: flex;
		flex-direction: row;
		column-gap: 20px;
		justify-content: space-between;

		.MuiOutlinedInput-root {
			width: 300px !important;
		}
	}
}

// Custom Footer
.order-footer {
	display: flex;

	.order-footer-content {
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-items: center;
		padding: 10px 0;

		p {
			font-size: 14px !important;
		}

		.order-pagination {
			display: flex;
			flex-direction: row;
			column-gap: 5px;
			align-items: center;

			button {
				color: #0075c9;
				font-size: 14px !important;

				& svg {
					color: white;
				}

				&.disabled {
					color: #e0f3ff !important;
				}
			}
		}
	}
}
