.order-form-container {
	display: flex;
	flex-direction: column;
	row-gap: 30px;

	.order-form-content {
		display: flex;
		flex-direction: column;
		row-gap: 30px;

		.order-form-pd,
		.order-form-opt {
			display: flex;
			flex-direction: column;
			row-gap: 20px;
			padding: 10px;
		}

		.order-form-opt {
			background-color: #fff;
		}
	}

	.order-form-content-add {
		display: flex;
		flex-direction: column;
		row-gap: 20px;

		.order-form-pd,
		.order-form-opt {
			display: flex;
			flex-direction: column;
			row-gap: 20px;
			padding: 15px 20px;
		}

		.order-form-opt {
			background-color: #fff;
			box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

			.order-form-opt-content {
				display: flex;
				flex-direction: column;
				row-gap: 20px;
			}

			.order-form-checkbox-container {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		}

		.order-form-helper-text {
			margin-left: auto;
			margin-right: auto;
			p {
				font-size: medium;
				color: #db3d47;
			}
		}
	}

	.order-form-header {
		display: flex;
		flex-direction: row;
		align-items: center;
		column-gap: 30px;

		h1 {
			font-size: 20px;
			color: #000;
			font-weight: 800;
		}

		.MuiSvgIcon-root {
			color: 'theme.palette.primary.contrastText';
			font-size: 24px;
		}

		img {
			width: 50px !important;
		}
	}

	.order-form-section-headers {
		display: grid;
		grid-template-columns: 1fr 4fr;
		column-gap: 30px;
		justify-content: space-between;
		align-items: center;
	}

	button .order-form-delete-icon {
		display: flex !important;
		justify-content: right !important ;
		align-content: flex-end !important;
	}

	.order-form-button {
		display: flex;
		margin: auto;
	}

	.order-form-input {
		display: grid;
		grid-template-columns: 1.2fr 4fr;
		column-gap: 30px;
		justify-content: space-between;
		align-items: center;
		border-radius: 7px !important;

		p {
			font-size: 14px !important;
		}

		span.required {
			color: #db3d47 !important;
		}

		fieldset {
			font-size: 14px !important;
		}

		.MuiInputBase-root {
			font-size: 14px !important;
		}

		.order-form-regions {
			display: flex;
			flex-direction: row;
			column-gap: 5px;
		}

		.MuiFormHelperText-root {
			margin: 5px 0 0 0 !important;
			font-size: 13px !important;
		}

		.phone-number {
			display: flex;
			flex-direction: row;
			column-gap: 5px;
		}
	}

	// Grid display
	.order-form-grid-input {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 30px;
	}

	.order-form-grid-checkbox {
		display: grid;
		grid-template-columns: 2fr 2fr 2fr 1fr;
		column-gap: 15px;
	}
}
