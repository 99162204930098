.temp-container {
	display: flex;
	flex-direction: column;
	row-gap: 30px;

	.temp-p-content {
		display: grid;
		column-gap: 30px;

		&.cnt {
			grid-template-columns: 1fr 1fr 1fr;
		}

		&.st {
			grid-template-columns: 2.06fr 1fr;
		}

		.temp-card {
			display: flex;
			flex-direction: column;
			row-gap: 20px;
			padding: 20px;
			border-radius: 10px;

			.temp-card-header {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.temp-card-icon {
					width: 30px;
					height: 30px;
					border-radius: 50%;
					padding: 10px;
					background-color: #fff;

					svg {
						font-size: 30px;
					}
				}
			}

			.temp-card-two {
				display: grid;
				grid-template-columns: 1fr 1fr;
				column-gap: 20px;
			}

			.site-col {
				display: flex;
				flex-direction: column;
				row-gap: 20px;

				.temp-colour-palette {
					display: grid;
					grid-template-columns: 1fr 1fr;
					border-radius: 10px;

					.col {
						display: flex;
						align-items: flex-end;
						padding: 15px;
					}
				}

				.desc {
					font-size: 14px;
				}
			}

			.temp-card-content {
				display: flex;
				flex-direction: column;
				row-gap: 15px;

				.temp-card-cnt-label {
					h2 {
						font-size: 40px;
					}

					h5 {
						font-size: 16px;
					}
				}

				h4 {
					font-size: 20px;
				}

				p {
					font-size: 14px;
				}
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.temp-p-content {
		display: grid;
		column-gap: 30px;
		row-gap: 30px;

		&.cnt {
			grid-template-columns: 1fr !important;
		}

		&.st {
			grid-template-columns: 1fr !important;
		}
	}
}

@media only screen and (max-width: 520px) {
	.temp-card-two {
		display: grid;
		grid-template-columns: 1fr !important;
		row-gap: 20px;
	}
}
