header {
	display: flex;
	justify-content: space-between;

	.header-title {
		display: flex;
		flex-direction: column;
		row-gap: 10px;

		button {
			max-width: max-content;
			font-size: 16px;
			color: #808080;
		}
	}
}
