.order-form-container {
	display: flex;
	flex-direction: column;
	row-gap: 30px;

	.order-form-content {
		display: flex;
		flex-direction: column;
		row-gap: 30px;

		.order-form-pd,
		.order-form-opt {
			display: flex;
			flex-direction: column;
			row-gap: 20px;
			padding: 20px;
		}

		.order-form-opt {
			background-color: #fff;
		}
	}

	.order-form-content-add {
		display: flex;
		flex-direction: column;
		row-gap: 10px;

		.order-form-pd,
		.order-form-opt {
			display: flex;
			flex-direction: column;
			row-gap: 20px;
			padding: 15px 20px;
		}

		.order-form-opt {
			background-color: #fff;
			box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

			.order-form-opt-content {
				display: flex;
				flex-direction: column;
				row-gap: 20px;
			}

			.order-form-checkbox-container {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		}
	}

	.order-form-header {
		display: flex;
		flex-direction: row;
		align-items: center;
		column-gap: 20px;

		h1 {
			font-size: 20px;
			color: #000;
			font-weight: 800;
		}

		.MuiSvgIcon-root {
			font-size: 24px;
		}

		img {
			width: 40px;
		}
	}

	.order-form-input {
		display: grid;
		grid-template-columns: 1fr 4fr;
		column-gap: 30px;
		justify-content: space-between;
		align-items: center;

		p {
			font-size: 14px !important;
		}

		span.required {
			color: #db3d47 !important;
		}

		fieldset {
			font-size: 14px !important;
		}

		.MuiInputBase-root {
			font-size: 14px !important;
		}

		.order-form-regions {
			display: flex;
			flex-direction: row;
			column-gap: 5px;
		}

		.MuiFormHelperText-root {
			margin: 5px 0 0 0 !important;
			font-size: 13px !important;
		}

		.phone-number {
			display: flex;
			flex-direction: row;
			column-gap: 5px;
		}
	}

	.fqdn-container {
		display: flex;
		flex-direction: column;
		row-gap: 20px;

		h2 {
			font-size: 20px;
			font-weight: 800;
		}

		h3 {
			font-size: 18px;

			& span {
				font-size: 18px !important;
				font-weight: 800;
			}
		}

		p {
			font-size: 14px !important;
		}

		h4 {
			font-size: 16px !important;
		}

		.fqdn-box {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.fqdn-content {
				display: flex;
				flex-direction: column;
				row-gap: 5px;
			}

			.fqdn-delete {
				display: flex;
				align-items: center;

				svg {
					color: #db3d47;
				}
			}
		}
	}

	.order-form-checkbox span {
		font-size: 14px !important;
	}

	// Tabs
	.MuiTabs-root {
		background-color: rgba(0, 0, 0, 0.05);
	}

	// Grid display
	.order-form-grid-input {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 30px;
	}

	.order-form-grid-checkbox {
		display: grid;
		grid-template-columns: 2fr 2fr 2fr 1fr;
		column-gap: 15px;
	}

	.order-form-additional {
		display: flex;
		flex-direction: column;
		row-gap: 30px;
	}

	.order-form-header-close {
		display: flex;
		justify-content: space-between;
		column-gap: 30px;
	}

	.MuiDataGrid-root .MuiDataGrid-cell {
		white-space: pre-line !important;
		word-wrap: normal !important;
	}

	.order-details-box {
		display: grid !important;
		grid-template-columns: auto auto auto auto !important;
		text-wrap: nowrap;
		row-gap: 10px;
	}

	.order-details-box h4 {
		font-weight: 600;
	}
}

.order-currency-box {
	display: flex;
	align-items: center;
	gap: 20px;
}
