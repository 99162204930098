.tn-address-update-container {
	display: flex;
	flex-direction: column;
	row-gap: 30px;

	.tn-address-update-header {
		display: flex;
		flex-direction: row;
		align-items: center;
		column-gap: 20px;

		h1 {
			font-size: 20px;
			color: #000;
			font-weight: 800;
		}

		.MuiSvgIcon-root {
			font-size: 24px;
		}

		img {
			width: 50px !important;
		}

		.modal-close-button {
			margin-left: auto;
		}
	}

	.tn-address-update-content {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 50px;
		justify-content: space-between;

		.tn-address-update-input {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin: 10px;
		}

		.tn-address-update-input p {
			white-space: nowrap;
			flex-grow: 1;
			flex-shrink: 0;
			width: 100px;
			margin-right: 10px;
		}

		.input-field {
			flex-grow: 1;
			width: 100%;
			box-sizing: border-box;
		}
	}

	.tn-address-update-footer {
		display: flex;
		justify-content: center;
		align-items: center;

		button {
			padding: 15px 60px !important;
			color: #fff !important;
			border-radius: 0 !important;
			margin-left: auto;
			margin-right: auto;
		}
	}
}
