.lead-container {
	display: flex;
	flex-direction: column;

	.lead-header {
		margin-bottom: 5px;
	}
}

.lead-form-container {
	display: flex;
	flex-direction: column;
	padding: 20px 30px;

	.lead-form-header {
		margin-bottom: 10px;
		h1 {
			font-size: 20px;
			font-weight: 700 !important;
			text-transform: uppercase;
		}
	}

	.lead-form-content {
		.lead-form-input {
			margin-top: 10px;
		}

		/* Styles for the left column */
		.left-column {
			width: 50%; /* Adjust the width as needed */
			float: left;
			box-sizing: border-box;
			padding: 10px;
		}

		/* Styles for the right column */
		.right-column {
			width: 50%; /* Adjust the width as needed */
			float: left;
			box-sizing: border-box;
			padding: 10px;
		}
	}
}
