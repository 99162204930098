.sda-card-container {
	width: 100%;
	margin: 0 auto;
	border-radius: 10px;
	display: flex;
	flex-direction: column;

	.sda-card-content {
		text-align: center;
		display: flex;
		flex-direction: column;
		flex: 1 1 100%;
		flex-wrap: wrap;
		align-items: center;
		align-content: center;
		justify-content: center;

		.sda-card-text.dt {
			-webkit-touch-callout: none; /* iOS Safari */
			-webkit-user-select: none; /* Safari */
			-khtml-user-select: none; /* Konqueror HTML */
			-moz-user-select: none; /* Old versions of Firefox */
			-ms-user-select: none; /* Internet Explorer/Edge */
			user-select: none;
		}

		.sda-card-body-img {
			max-width: 100px;
			height: auto;
			margin-bottom: 8px;
		}

		.sda-card-overview {
			text-align: center;

			h2 {
				font-size: 40px;
				font-weight: 700 !important;
			}

			h5 {
				font-size: 16px;
				font-weight: 600 !important;
			}
		}
	}
}
