.pricebook-overview-container {
	display: flex;
	flex-direction: column;
	row-gap: 20px;
	column-gap: 20px;

	.pricebook-overview-filters {
		margin-bottom: 1rem;

		.MuiOutlinedInput-root {
			width: 300px !important;
		}

		.pricebook-heading {
			font-size: 20px !important;
		}
		.pricebook-sku-heading {
			margin-top: 1.5rem;
			margin-bottom: 1rem;
		}

		.pricebook-fxrates-container {
			display: flex;
			flex-direction: column;
		}

		.pricebook-currency-heading {
			margin-top: 1.5rem;
			margin-bottom: 1rem;
		}

		.pricebook-currency-display {
			margin-bottom: 1rem;
		}

		.pricebook-element {
			width: 300px;
		}

		.currency-container {
			display: flex;
			column-gap: 60px;
			align-items: baseline;
		}
	}
}
