.comm-mgt-container {
	display: flex;
	flex-direction: column;
	row-gap: 30px;

	section {
		display: grid;
		grid-template-columns: 1fr 2fr;
		column-gap: 150px;

		.section-desc {
			display: flex;
			flex-direction: column;
			row-gap: 15px;
			flex: 1;
		}

		.section-form {
			display: flex;
			flex: 1;
			flex-direction: column;
			row-gap: 20px;

			.form-table {
				display: grid;
				grid-template-columns: 1fr 3fr;
				column-gap: 20px;

				.lbl-flex {
					display: flex;
					align-items: center;
				}
			}
		}

		.section-content {
			display: flex;
			flex-direction: column;
			flex: 1;
			row-gap: 20px;
			justify-content: flex-end;
		}

		.form-flex {
			display: flex;
			flex-direction: column;
			row-gap: 5px;
		}

		.btn {
			display: flex;
			justify-content: flex-start;
		}
	}
}
