.image-upload-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 2px dashed #000;
	height: 200px;
	cursor: pointer;
	border-radius: 10px;
	padding: 20px;
	text-align: center;

	.default-logo img {
		margin-bottom: 10px;
	}

	.logo-upload img {
		max-width: 300px;
		max-height: 150px;
	}
}

.image-details-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 5px 10px;
	background-color: rgb(37, 36, 35, 0.1);

	.MuiSvgIcon-root {
		font-size: 18px;
	}
}
