.tum-login-box {
	display: flex;
	flex-direction: column;
	row-gap: 20px;

	.acc-details {
		display: flex;
		flex-direction: row;
		column-gap: 20px;
		align-items: center;
		padding: 10px;
		border-radius: 10px;

		svg {
			font-size: 40px;
		}

		.acc-text {
			display: flex;
			justify-content: flex-start;
			flex-direction: column;
			row-gap: 10px;
			text-align: left;
		}
	}

	.login {
		display: flex;
		flex-direction: column;
		row-gap: 10px;
		align-items: center;
	}
}
