accept-compliance-container {
	padding: 50px 100px;
}

.accept-compliance-content {
	max-width: 850px;
	margin: 0 auto;
	background-color: #fafafa;
}

.accept-compliance-header h1 {
	display: flex;
	align-content: center;
	flex-wrap: wrap;
}

.accept-compliance-header img {
	width: 40px;
}

.accept-compliance-header {
	padding: 30px;
	display: flex;
	justify-content: center;
	column-gap: 20px;
}

.accept-compliance-text {
	padding: 50px;
}

.accept-compliance-text p {
	margin-bottom: 5px;
}

.accept-compliance-text h2 {
	font-size: 22px;
	margin-bottom: 5px;
}

.accept-compliance-text h3 {
	font-size: 18px;
	margin-bottom: 5px;
}

.accept-compliance-text list,
.accept-compliance-text list {
	margin-bottom: 5px;
}

.accept-compliance-text list list-item,
.accept-compliance-text list list-item {
	list-style: circle;
	list-style-position: inside;
	margin-bottom: 5px;
	display: list-item;
}

.accept-compliance-button {
	padding-bottom: 20px;
	text-align: center;
}

.accept-compliance-button .top-margin {
	margin: 20px !important;
}

.accept-compliance-text a {
	text-decoration: none !important;
}
