main {
	display: flex;
	min-height: 100vh;

	.content {
		flex: 1;
		overflow: auto;
		display: flex;
		flex-direction: column;
		row-gap: 20px;
	}
}

// ** Used for login
.content:has(div.login-container) {
	display: flex;
	flex-direction: column;
}
