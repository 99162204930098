// Import from root-style
@import '../../../../../../../styles/root-style.scss';

.tum-edit-form-container {
	display: flex;
	flex-direction: column;
	row-gap: 20px;

	// User
	.form-user-info {
		display: flex;
		flex-direction: row;
		column-gap: 15px;
		align-items: center;
		padding: 15px;
		border-radius: 15px;
		background: var(--bg-secondary);
		border: 1px solid var(--border) !important;

		.avatar {
			border-radius: 15px !important;
			font-size: 20px !important;
		}
	}

	.tum-form-content {
		display: flex;
		flex-direction: column;
		row-gap: 20px;
		padding: 25px 0;
	}

	// Config
	.form-config {
		display: flex;
		flex-direction: column;
		row-gap: 10px;

		.form-label-error {
			display: flex;
			justify-content: space-between;
			column-gap: 20px;
		}

		.form-label-container {
			display: flex;
			flex-direction: row;
			column-gap: 10px;
			align-items: center;

			.icon-button {
				padding: 2px !important;

				svg {
					font-size: 20px;
				}
			}
		}

		p.form-label {
			font-weight: 600 !important;
		}

		.form-input-wlbl {
			display: grid;
			grid-template-columns: 1fr 4fr;
			column-gap: 20px;

			.input-label {
				display: flex;
				align-items: center;
			}
		}
	}

	// Checkboxes
	.phone-checkboxes {
		display: flex;
		justify-content: space-between;
	}

	.license-checkbox {
		display: flex;
		justify-content: flex-end;
	}
}

// Autocomplete Styling
.autocomplete-container {
	position: relative;
	width: 100%; // Ensure the container takes the full width of its parent

	input {
		width: 100%; // Make sure the input takes the full width of the container
		box-sizing: border-box; // Include padding and border in the element's total width and height
		padding-right: 24px; // Add space for the clear icon
		padding: 10px 24px 10px 10px;
		border-radius: 10px;
		border: 1px solid;
	}

	.clear-icon {
		position: absolute;
		right: 8px;
		top: 50%;
		transform: translateY(-50%);
		cursor: pointer;
	}

	.loading {
		position: absolute;
		right: 8px;
		top: 20%;
		transform: translateY(-50%);
	}

	ul {
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		max-height: 150px; // Optional: Limit the height of the dropdown for large lists
		overflow-y: auto; // Optional: Add scroll for large lists
		background-color: white;
		border: 1px solid #ccc;
		border-radius: 10px;
		box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
		z-index: 10000; // Ensure the dropdown appears above other elements
		padding-inline-start: 0;

		li {
			padding: 8px 12px;
			cursor: pointer;
			white-space: normal; /* Allow text to wrap */
			word-break: break-word; /* Break words when necessary */
			overflow-wrap: break-word; /* Ensure long words break to fit within the container */

			&.disabled-item {
				cursor: not-allowed;
				color: #808080 !important;
			}

			&:hover,
			&.active {
				background-color: #f0f0f0;
			}

			// Menu Options Styling
			.menu-item {
				display: flex;
				align-items: center;
				width: '100%';
				column-gap: 5px;

				svg {
					font-size: 20px;
					align-items: flex-end;
				}
			}
		}
	}
}

// Tooltip
.MuiPopover-paper {
	border-radius: 15px !important;
}
.tum-tooltip-container {
	display: flex;
	flex-direction: column;
	row-gap: 15px;
	padding: 15px;

	.tooltip-title {
		display: flex;
		flex-direction: column;
		row-gap: 5px;
	}

	ul {
		display: flex;
		flex-direction: column;
		row-gap: 10px;
	}

	p,
	li {
		font-size: 13px !important;
	}

	.tooltip-legend-container {
		display: flex;
		flex-direction: column;
		row-gap: 15px;

		.tooltip-legend {
			display: flex;
			column-gap: 10px;
		}
	}
}
