/* Colour picker style */
.colour-picker {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-color: transparent;
	width: 50px;
	height: 50px;
	border: none;
	cursor: pointer;

	// Other site styling
	&::-webkit-color-swatch {
		border-radius: 50%;
		border: 4px solid #000000;
	}

	&::-moz-color-swatch {
		border-radius: 50%;
		border: 4px solid #000000;
	}
}
