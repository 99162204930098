.customer-overview-container {
	display: flex;
	flex-direction: column;
	row-gap: 30px;

	.customer-overview-content {
		display: grid;
		grid-template-columns: 1.5fr 2fr;
		row-gap: 30px;

		.customer-information-container {
			display: flex;
			flex-direction: row;
			column-gap: 30px;
			align-items: center;

			.MuiAvatar-root {
				width: 80px !important;
				height: 80px !important;
				font-size: 30px !important;
			}

			.customer-information {
				display: flex;
				flex-direction: column;
				row-gap: 15px;

				.info {
					display: grid;
					grid-template-columns: 1fr 2fr;
					column-gap: 25px;

					label {
						font-weight: 600;
					}

					label,
					p {
						display: flex;
						align-items: center;
					}

					.MuiChip-root {
						max-width: max-content;
						padding: 10px;
					}

					.MuiInputBase-root {
						background-color: #fff;
					}
				}
			}
		}
	}
}
