.login-container {
	min-height: 100vh;
	background-image: url('../../assets/images/generic/background/bg.png');
	background-position: center;
	background-size: auto;
	display: flex;
	align-items: center;
	justify-content: center;

	.login-content {
		max-width: fit-content;
		max-height: fit-content;
		padding: 50px;
		border-radius: 25px;
		background-color: #fff;
		display: flex;
		flex-direction: column;
		row-gap: 30px;
		align-items: center;
		justify-content: center;
		box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

		.login-text {
			text-align: center;
			display: flex;
			flex-direction: column;
			row-gap: 10px;
			align-items: center;
		}

		img {
			max-width: 200px;
			height: auto;
		}
	}
}

.faq {
	text-align: start;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	span,
	li {
		margin: 10px 0;
	}
}

// Hide sidebar if not logged in
#main:has(#login) {
	display: block;

	#sidemenu,
	#header {
		display: none;
	}

	#content {
		padding: 0;
	}
}
