.add-address-box {
	display: grid;
	grid-template-columns: 2fr 2fr 2fr 2fr;
	column-gap: 50px;
	row-gap: 30px;
	padding: 40px;
}

.address-btn {
	display: flex !important;
	margin: auto !important;
}

span.required {
	color: #db3d47 !important;
}

.header {
	padding-bottom: 20px;
}
