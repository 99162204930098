.remove-user {
	display: flex;
	align-items: center;
	flex-direction: column;
	background-color: #fff;
	padding-top: 1.5rem;
}

.text {
	width: 100%;
}

.text-container {
	width: 500px;
	margin: 1rem auto;
}

.btn {
	margin-bottom: 1rem;
}
.btn-cancel {
	margin-left: 1rem;
}
.btn-remove {
	color: #fff;
	margin-right: 1rem !important;
}
.select-field {
	width: 100%;
	margin: 20px 0 !important;
	font-size: medium;
}
.email-field {
	margin: 20px 0 !important;
}
