.license-selection-container {
	padding-top: 10px;
	margin: 0 auto;
	width: 450px;
	overflow: hidden;
}

.license-selection-label {
	display: flex;
	justify-content: center;
}

.license-selection-select {
	width: 100%;
	margin: 5px 0 !important;
	margin-top: 10px;
	font-size: medium;
}

.license-selection-button {
	margin-top: 5px;
	display: flex;
	justify-content: center;
}
