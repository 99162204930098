.tn-address-container {
	display: flex;
	flex-direction: column;
	row-gap: 30px;

	.tn-address-header {
		display: flex;
		flex-direction: row;
		align-items: center;
		column-gap: 20px;

		h1 {
			font-size: 20px;
			color: #000;
			font-weight: 800;
		}

		.MuiSvgIcon-root {
			font-size: 24px;
		}

		img {
			width: 50px !important;
		}

		.modal-close-button {
			margin-left: auto;
		}
	}
}
