.form-group {
	display: flex;
	align-items: left;
	flex-direction: row;
	padding-top: 1.5rem;
	margin-bottom: 10px;
}

.btn {
	margin-bottom: 1rem;
}

.val-results {
	width: 80%;
	margin-bottom: 10px;
}
