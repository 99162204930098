// Import font - Manrope
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

// Root Styling - All the var styling that can be reused throughout the site
:root {
	// General styling (Includes font size, padding, margin, etc.)
	// Colours
	--bg-primary: #fff;
	--bg-secondary: #f9f9fb;
	--df-primary: #5341b2;
	--df-secondary: #edeaff;

	// Font
	--text-size: 16px;

	// Border
	--border: rgba(146, 160, 186, 0.15);
	--border-radius: 20px;

	// Spacing
	--padding: 20px;
}

// Mixins for custom classes
@mixin text {
	font-family: 'Manrope', sans-serif !important;
	font-size: 14px !important;
	font-weight: 400 !important;
}

@mixin heading {
	font-family: 'Manrope', sans-serif !important;
	font-size: 26px !important;
	font-weight: 800 !important;
}

@mixin heading-sml {
	font-family: 'Manrope', sans-serif !important;
	font-size: 20px !important;
	font-weight: 800 !important;
}

@mixin sub-heading {
	font-family: 'Manrope', sans-serif !important;
	font-size: 16px !important;
	font-weight: 600 !important;
}

@mixin flex-box-col {
	display: flex;
	flex-direction: column;
	row-gap: 30px;
}

@mixin flex-box-ctr {
	display: flex;
	justify-content: center;
}

// General styling
* {
	margin: 0;

	// Font and text styling
	h1,
	h2 {
		@include heading;
	}

	h3 {
		@include heading-sml;
	}
	h4,
	h5,
	h6 {
		@include sub-heading;
	}

	p,
	span,
	a,
	button,
	ul,
	li,
	div,
	td,
	tr,
	th,
	input,
	select,
	option {
		@include text;
	}
}

// Custom button radius
button {
	border-radius: 10px !important;
}
