.stepper-component {
	padding: 10px 5px;
}

.centerBox {
	display: flex;
	justify-content: center;
}

.error-container {
	width: 800px;
	margin: auto;
	padding: 15px;
	text-align: center;
	border: 1px solid #d0d4d9;
	border-radius: 10px;
	overflow: hidden;
	background-color: #fdeded;
}

.order-and-user-info {
	margin: 0 auto;
	margin-bottom: 10px;
}

.error-container-button {
	justify-content: center;
	margin-top: 5px;
}

.loading-container {
	margin: auto;
	margin-top: 80px;
	width: 400px;
}

.login-card-container {
	margin: 50px 0;
}
