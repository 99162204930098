.avatar-box-container {
	display: flex;
	flex-direction: row;
	column-gap: 15px;
	align-items: center;

	.avatar {
		border-radius: 15px !important;
		width: 40px;
		height: 40px;
	}

	.avatar-box-text {
		display: flex;
		flex-direction: column;
		row-gap: 2px;

		h4 {
			font-size: 15px !important;
		}

		p {
			font-size: 12px !important;
		}
	}
}

// Tooltip design
.avatar-tooltip {
	h4 {
		font-size: 15px !important;
	}

	p {
		font-size: 12px !important;
	}
}
